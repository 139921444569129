<template>
  <div>
    <div>
      <input
        type="file"
        style="display: none"
        ref="upload"
        accept="image/*"
        :single="true"
        @change="onChange"
      />
      <a-button type="primary" :loading="loading" @click="onClick">
        {{ list.length > 0 ? "继续上传" : "点击上传" }}
      </a-button>
    </div>

    <Padding />

    <a-table bordered :data-source="list" :pagination="false">
      <a-table-column title="图片">
        <template slot-scope="text">
          <a :href="text.completePath" target="_blank">{{ text.name }}</a>
        </template>
      </a-table-column>
      <a-table-column title="操作" align="center">
        <template slot-scope="text, record, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
  
<script>
import { uploadFile } from "@/api/upload";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    onClick() {
      this.$refs.upload.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      this.loading = true;

      uploadFile(data)
        .then((res) => {
          console.log("upload res", res);
          if (Array.isArray(res)) {
            this.$emit("change", [...this.list, ...res]);
          }
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    },

    remove(index) {
      const arr = [...this.list];
      arr.splice(index, 1);
      this.$emit("change", arr);
    },
  },
};
</script>